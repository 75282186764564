<template>
  <div>
    <siteHeader />

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <h3 class="mb-0">Email Template</h3>
            </div>
            <div class="card-body">
              <br />
              <div class="row1">
                <div class="table-responsive">
                  <div class="document-editor">
                    <div class="document-editor__toolbar"></div>
                    <div class="document-editor__editable-container">
                      <div class="document-editor__editable">
                        <ckeditor
                          @ready="onReady"
                          :editor="editor"
                          v-model="model.email"
                          :config="editorConfig"
                          @fileUploadRequest="onFileUploadRequest($event)"
                          @fileUploadResponse="onFileUploadResponse($event)"
                        ></ckeditor>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="card-footer d-flex justify-content-end"
                  :class="type === 'dark' ? 'bg-transparent' : ''"
                >
                  <!-- <base-pagination total="30"></base-pagination> -->
                </div>
              </div>
            </div>

            <div v-if="isLoading">
              <base-alert type="success" dismissible>
                <span class="alert-inner--text"
                  ><strong>Success!</strong> Record Created Successfully</span
                >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </base-alert>
            </div>

            <div class="row">
              <div class="col-md-5"></div>
              <div class="col-md-4">
                <button
                  type="button"
                  class="btn btn-default mb-4"
                  v-on:click="save()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const emailRepository = RepositoryFactory.get("email");
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
export default {
  data() {
    return {
      tableData: [],
      model: { email: "" },
      isLoading: false,
      editor: DecoupledEditor,
      editorData: "<p>Rich-text editor content.</p>",
      editorConfig: {
        // The configuration of the rich-text editor.
      },
    };
  },
  methods: {
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    async save() {
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await emailRepository.create(data);
      if (response.data.status == 100 || response.data.status == 101) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      }
    },
    getEmail() {
      emailRepository.getEmail().then((data) => {
        this.tableData = Object.freeze(data);
        if (this.tableData.data[0]) {
          this.model = this.tableData.data[0];
        }
      });
    },
    onFileUploadRequest(evt) {
      console.log(evt);
    },
    onFileUploadResponse(evt) {
      console.log(evt);
    },
  },
  mounted() {
    this.getEmail();
  },
};
</script>
<style>
.document-editor__editable-container .ck-editor__editable {
  /* Set the dimensions of the "page". */
  width: 30com;
  min-height: 5cm;

  /* Keep the "page" off the boundaries of the container. */
  /* padding: 1cm 2cm 2cm; */

  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  /* Center the "page". */
  margin: 0 auto;
}
</style>
